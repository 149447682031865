import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./LinkButton.module.css";

export default function LinkButton(props) {
  const style = {
    marginRight: "9px",
    fontSize: "26px",
    color: "#1b91cf"
  }

  if (props.link.route) return <NavLink to={props.link.url} className={styles.linkButton}>{props.link.name}</NavLink>

  return <a href={props.url} className={styles.linkButton} target={props.target}>
    {props.icon && <FontAwesomeIcon icon={props.icon} style={style} />}
    {props.name}
  </a>
}
