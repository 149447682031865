import { useTranslation } from "react-i18next";
import styles from "./GMNavigation.module.css";

export default function GMNavigation() {
  const { t } = useTranslation();

  return <nav>
    <ul className={styles.gmnav_list}>
      <li><a href="https://r.aww.xyz/wp-admin/post-new.php">{t("new_post")}</a></li>
      <span className={styles.delimeter}>|</span>
      <li><a href="https://tlpe.ru/admin/">ACP (TLPE)</a></li>
      <li><a href="https://forum.netstalking.org/admin">ACP (49406)</a></li>
      <li><a href="https://r.aww.xyz/wp-admin/">{t("blog_acp")}</a></li>
      <span className={styles.delimeter}>|</span>
      <li><a href="https://cloud.aww.xyz/">{t("cloud")}</a></li>
    </ul>
  </nav>
}
