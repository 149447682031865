import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from "./SocialTile.module.css";


export default function SocialTile(props) {
  const [isHovered, setIsHovered] = useState(false);

  function handleMouseEnter() {
    setIsHovered(true);
  }

  function handleMouseLeave() {
    setIsHovered(false);
  }

  const tileColor = {
    backgroundColor: isHovered ? props.color : 'transparent',
    color: isHovered ? "white" : props.color,
  };

  return <a
    href={props.url}
    className={styles.tile}
    style={tileColor}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    target="_blank"
    rel="noreferrer">
      <FontAwesomeIcon icon={props.icon} />
    </a>
}
