import {useTranslation} from "react-i18next";
import styles from "./AboutMe.module.css";
import {NavLink} from "react-router-dom";

export default function AboutMe() {
  const { t } = useTranslation();

  return <div className={styles.about_wrapper}>
    <div>
      <NavLink to={"/"} className={styles.navLine_link}>{t("nav.main")}</NavLink>
    </div>
    <div className={styles.about}>
      <h1>{t("about.title")}</h1>
      <p>{t("about.i_am")}<br />{t("about.below")}</p>
      <p>{t("about.hobbies")}</p>
      <p>{t("about.create")} <a href="https://tulpamancy.org/">TLPE</a> {t("about.and")} <a href="https://netstalking.org/">netstalking.org</a>. {t("about.also_hosting")}</p>
      <p>{t("about.tg_blogs")} <a href="https://t.me/rwarden">{t("about.personal_blog")}</a>, <a href="https://t.me/rescor_and_ahri">{t("about.tulpa_blog")}</a> {t("about.and")} <a href="https://t.me/runetstalking">{t("about.netstalking_channel")}</a>.</p>
      <p>{t("about.member")} <a href="https://lodz.hackerspace.pl/" target="_blank" rel="noreferrer">Łódź Hackerspace</a>.</p>
      <p>{t("about.also_like")}</p>
      <p>{t("about.fan")} "<a href="https://parahumans.wordpress.com/" target="_blank" rel="noreferrer">{t("about.worm")}</a>", {t("about.fan_things")}</p>
      <p>{t("about.acquainted")}</p>
    </div>
  </div>
}
