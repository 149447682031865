import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import MainPage from "./pages/MainPage";
import Terminal from "./components/Terminal/Terminal";
import RootPage from "./pages/RootPage";
import AboutMe from "./components/AboutMe/AboutMe";
import GMNavigation from "./components/GMNavigation/GMNavigation";
import './App.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootPage />,
    errorElement: <Navigate to="/" />,
    children: [
      {index: true, element: <MainPage />},
      {path: "me", element: <AboutMe />},
    ],
  }
])

function App() {
  const [isTerminalOpen, setIsTerminalOpen] = useState(false);
  const gmMode = localStorage.getItem("gm");
  const handleKeyPress = (event) => {
    // better support for all browsers
    if (event.code === "Backquote" || event.key === "`" || event.keyCode === 192) {
      event.preventDefault();
      setIsTerminalOpen(prev => !prev);
    }

    if ((event.code === "Escape" || event.key === "Escape") && isTerminalOpen) {
      setIsTerminalOpen(false)
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isTerminalOpen]);

  return <I18nextProvider i18n={i18n}>
      <>
        {gmMode && <GMNavigation />}
        <RouterProvider router={router} />
        {isTerminalOpen && <Terminal />}
      </>
    </I18nextProvider>
}

export default App;
