import React, { useState, useRef } from 'react';
import styles from "./AudioPlayer.module.css";
import bg1 from "../../../assets/bg1.mp3";
import {ReactComponent as PlayButton} from "../../../assets/play.svg";
import {ReactComponent as PauseButton} from "../../../assets/pause2.svg";

function AudioPlayer() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);

  const audioRef = useRef(null);

  const tracks = [
    // Список ваших аудиофайлов
    { src: bg1, title: 'Песня 1' },
    { src: bg1, title: 'Песня 2' },
    // Добавьте другие треки по аналогии
  ];

  const currentTrack = tracks[currentTrackIndex];

  const togglePlayPause = () => {
    const audio = audioRef.current;
    setIsPlaying(!isPlaying);

    if (isPlaying) {
      audio.pause();
    } else {
      audio.play();
    }
  };

  const changeVolume = (e) => {
    const audio = audioRef.current;
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    audio.volume = newVolume;
  };

  const nextTrack = () => {
    if (currentTrackIndex < tracks.length - 1) {
      setCurrentTrackIndex(currentTrackIndex + 1);
      setIsPlaying(true);
    }
  };

  return (
    <div className={styles.player}>
      <audio ref={audioRef} src={currentTrack.src}></audio>
      <h2>{currentTrack.title}</h2>
      <div className="controls">
        <div onClick={togglePlayPause} className={styles.play}>
          {isPlaying ? <PlayButton /> : <PauseButton />}
        </div>
        <button onClick={nextTrack}>Следующий трек</button>
      </div>
      <div className="volume">
        <input
          type="range"
          min="0"
          max="1"
          step="0.01"
          value={volume}
          onChange={changeVolume}
        />
      </div>
    </div>
  );
}

export default AudioPlayer;
