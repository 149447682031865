import SocialTile from "./SocialTile";
import { faVk } from '@fortawesome/free-brands-svg-icons';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faReddit } from '@fortawesome/free-brands-svg-icons';
import { faSteam } from '@fortawesome/free-brands-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faS } from '@fortawesome/free-solid-svg-icons';
import styles from "./SocialBar.module.css";

export default function SocialBar() {
  const socialLinks = [
    { icon: faTelegram, url: "https://t.me/rescor", color: "#1b91cf" },
    { icon: faGithub, url: "https://github.com/Rescor", color: "#8c8c8c" },
    { icon: faS, url: "https://spaces.im/mysite/index/rescor", color: "#aa84d6" },
    { icon: faInstagram, url: "https://www.instagram.com/rawrescor/", color: "#c32aa3" },
    { icon: faReddit, url: "https://www.reddit.com/user/RawRescor", color: "#ff5700" },
    { icon: faSteam, url: "https://steamcommunity.com/id/rwarden/", color: "#6b859d" },
    { icon: faVk, url: "https://vk.com/rescor", color: "#0077ff" },
    { icon: faDiscord, url: "https://discordapp.com/users/218890000509435914", color: "#7289da" },
  ]

  return <div className={styles.social_bar_container}>
    <div className={styles.social_bar}>
      {socialLinks.map(link => <SocialTile
        icon={link.icon}
        url={link.url}
        color={link.color}
        key={link.url} />)}
    </div>
  </div>
}
