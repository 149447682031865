import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { CSSTransition } from "react-transition-group";
import MobileNavigation from "../Navigation/MobileNavigation";
import SwipeArrows from "../UI/SwipeArrows/SwipeArrows";

export default function ScreenSelector(props) {
  const [swipeStatus, setSwipeStatus] = useState(false);
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setSwipeStatus(true);
      props.setIsSwiped(true);
    },
    onSwipedRight: () => {
      setSwipeStatus(false);
      props.setIsSwiped(false);
    }
  });

  return <div {...handlers} style={{width: "100%", top: 0, zIndex: 0, height: "100dvh", position: "absolute"}}>
    <CSSTransition
      in={swipeStatus}
      classNames="fade"
      timeout={250}
    >
      {swipeStatus ? <MobileNavigation links={props.links} /> : <></>}
    </CSSTransition>

    <SwipeArrows direction={swipeStatus ? 'right' : 'left'} />
  </div>
}
