import LinkButton from "./LinkButton/LinkButton";
import styles from "./MobileNavigation.module.css";

export default function MobileNavigation(props) {
  return <div className={styles.navWrapper}>
    <div className={styles.nav}>
      {props.links.map(link => <LinkButton
        link={link}
        name={link.name}
        url={link.url}
        icon={link.icon}
        target={link.target}
        key={link.url} />)}
    </div>
  </div>
}
