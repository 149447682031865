import bg from "../../../assets/bg.mp4";
import styles from "./BackgroundScreen.module.css";

export default function BackgroundScreen() {
  return <>
    <video className={styles.video_background} autoPlay loop muted>
      <source src={bg} type="video/mp4" />
    </video>
    <div className={styles.video_overlay}></div>
  </>
}
