import styles from "./CyberFox.module.css";

export default function CyberFox() {
  return <pre className={styles.cyberfox}>{`
                                                                          ,-,
                                                                     _.-=;~ /_
                                                                  _-~   '     ;.
                                                              _.-~     '   .-~-~\`-.__
                                                        _.--~~:.             --.____/
                                      ____.........--~~~. .' .  .        _..~~~~~~~~
                             _..--~~~~               .' .'             ,'
                         _.-~                        .       .     \` ,'
                       .'                                    :.    ./
                     .:     ,/          \`                   ::.   ,'
                   .:'     ,(            ;.                ::. ,-'
                  .'     ./'.\`.     . . /:::._______.... _/:.o/
                 /     ./'. . .)  . _.,'               \`88;?88|
               ,'  . .,/'._,-~ /_.o8P'                  88P ?8b
            _,'' . .,/',-~    d888P'                    88'  88|
         _.'~  . .,:oP'        ?88b              _..--- 88.--'8b.--..__
        :     ...' 88o __,------.88o ...__..._.=~- .    \`~~   \`~~      ~-.
        \`.;;;:='    ~~            ~~~                ~-    -       -   -
        `}
  </pre>;
}