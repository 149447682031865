import styles from "./Name.module.css";

export default function Name() {
  const isArgEnabled = false;
  const argText = '🦊';

  const handleCopy = event => {
    event.preventDefault();
    event.clipboardData.setData('text/plain', argText);
  }

  return <p className={styles.name} onCopy={isArgEnabled ? handleCopy : null}>好奇心旺盛な生き物</p>
}
