import styles from "./TerminalInput.module.css";
import {useEffect, useRef} from "react";

export default function TerminalInput() {
  const gmMode = localStorage.getItem("gm");
  const terminalInput = useRef();
  useEffect(() => {
    terminalInput.current.focus();
  }, []);

  function submitHandler(event) {
    event.preventDefault();
    if (event.target[0].value === "gm on") {
      localStorage.setItem("gm", "1")
    }
    if (event.target[0].value === "gm off") {
      localStorage.removeItem("gm")
    }
    terminalInput.current.value = "";
  }

  return <div className={styles.terminalInputWrapper}>
    <span>{gmMode ? "~rescor@aww.xyz >" : "~guest@aww.xyz >" }</span>
    <form onSubmit={submitHandler} className={styles.inputForm}>
      <input ref={terminalInput} type="text" className={styles.terminalInput} name="terminal" id="terminal" />
    </form>
  </div>
}