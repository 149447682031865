import { useEffect, useState } from "react";
import avatar from "../../assets/avatar.jpg";
import styles from "./ProfileWidget.module.css";

function getStatusClass(status) {
  const statuses = {
    1: "online",
    3: "away",
  }

  return statuses[status] || "offline";
}

export default function ProfileWidget(props) {
  const [statusClass, setStatusClass] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      const response = await fetch('https://rsx.aww.xyz/online.php');
      const data = await response.json();
      setStatusClass(getStatusClass(data));
    } catch (error) {
      console.error(error);
    }
  }

  return <div className={styles.profile}>
    <div>
      <img src={avatar} alt="avatar" className={styles.avatar}/>
      <div className={styles.status_indicator + " " + styles[statusClass]}></div>
    </div>
    <p>Rescor</p>
  </div>
}
